<template>
  <form @submit.prevent="addCredit">
    <modal-card :title="`Grant account credit`" :processing="processing">
      <b-field label="Currency">
        <b-select v-model="currency" :disabled="processing" expanded>
          <option v-for="(c, key) in currencies" :key="key" :value="key">
            {{ c.code }} {{ c.prefix || c.suffix }}
          </option>
        </b-select>
      </b-field>

      <b-field label="Amount*">
        <b-input
          ref="amount"
          v-model.number="credit"
          :disabled="processing"
          :max="isOnlyAgent ? 50 : null"
          type="number"
          step="0.01"
          min="0"
        />
      </b-field>

      <b-field label="Note*">
        <b-input v-model="note" :disabled="processing" type="textarea" />
      </b-field>

      <button
        slot="footer"
        :class="{ 'is-loading': processing }"
        :disabled="!isValid || processing"
        type="submit"
        class="button is-success"
      >
        Add
      </button>
    </modal-card>
  </form>
</template>

<script>
import currencies from "@src/data/currencies.json";
export default {
  name: "AddCreditModal",
  props: {
    userId: { type: String, required: true }
  },
  data() {
    return {
      processing: false,
      currency: "GBP",
      credit: null,
      note: "",
      currencies
    };
  },

  computed: {
    user() {
      return this.$store.getters["user/user"]();
    },
    isOnlyAgent() {
      return this.$store.getters["user/isOnlyAgent"]();
    },
    isValid() {
      if (
        this.$_.isEmpty(this.note) ||
        this.$_.isEmpty(this.currency) ||
        !this.$_.isNumber(parseFloat(this.credit)) ||
        this.credit <= 0
      ) {
        return false;
      }

      if (this.isOnlyAgent && this.credit > 50) {
        return false;
      }

      return true;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.amount.$el.querySelector("input").focus();
    });
  },
  methods: {
    addCredit() {
      this.processing = true;
      this.$functions()
        .httpsCallable("onCall-user-addCredit")({
          userId: this.userId,
          currency: this.currency,
          amount: parseFloat(this.credit),
          note: this.note
        })
        .then(result => {
          this.$toast.open({
            message: result.data.message,
            position: "is-bottom",
            queue: false,
            type: "is-success"
          });
        })
        .catch(error => {
          this.$toast.open({
            message: error.message,
            position: "is-bottom",
            queue: false,
            type: "is-danger"
          });
        })
        .then(() => {
          this.$emit("close");
          this.processing = false;
        });
    }
  }
};
</script>
